<template>
  <b-card>

    <b-row>
      <b-button
        class="btn-filter"
        variant="adn"
        @click="() => {
          showFilter = !showFilter;
          ResetSelection();
        }"
      >
        {{ !showFilter ? this.$t('showFilter') : this.$t('hideFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="danger"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilter"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="transactionNumber"
          v-silent-search
          :value.sync="filter.transactionNumber"
          label-text="transactionNumber"
          name="transactionNumber"
          @keydown.enter="() => { refreshItems(); }
          "
        />
      </b-col>

      <b-col
        class="gb-package"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("transactionType") }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', { btnActive: transactionGroupName === 'all' }]"
            variant="flat-warning"
            @click="() => {
              transactionGroupName = 'all'
              refreshItems()
            }"
          >
            {{ $t("all") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: transactionGroupName === 'sales' }]"
            variant="flat-warning"
            @click="() => {
              transactionGroupName = 'sales'
              refreshItems()
            }"
          >
            {{ $t("salesInvoices") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: transactionGroupName === 'returns' }]"
            variant="flat-warning"
            @click="() => {
              transactionGroupName = 'returns'
              refreshItems()
            }"
          >
            {{ $t("returnSales") }}
          </b-button>
        </b-button-group>
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('fromDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.fromDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="fromDate"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('toDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.toDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="toDate"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="studentName"
          v-silent-search
          :value.sync="filter.studentName"
          label-text="studentName"
          name="studentName"
          @keydown.enter="() => { refreshItems(); }
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.stageId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="stage"
          field="select"
          :options="stages"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.rowId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="row"
          field="select"
          :options="rows"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.classroomId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="class"
          field="select"
          :options="classrooms"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <!-- group type selection -->
      <b-col
        class="gb-package"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("accountsPostSituation") }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', { btnActive: postGroupName === 'all' }]"
            variant="flat-warning"
            @click="() => {
              postGroupName = 'all'
              refreshItems()
            }"
          >
            {{ $t("all") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: postGroupName === 'posted' }]"
            variant="flat-warning"
            @click="() => {
              postGroupName = 'posted'
              refreshItems()
            }"
          >
            {{ $t("posted") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: postGroupName === 'unPosted' }]"
            variant="flat-warning"
            @click="() => {
              postGroupName = 'unPosted'
              refreshItems()
            }"
          >
            {{ $t("unPosted") }}
          </b-button>
        </b-button-group>
      </b-col>

      <!-- group type selection for sending to zatca -->
      <b-col
        class="gb-package"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("postingToZatcaStatus") }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', { btnActive: zatcaGroupName === 'all' }]"
            variant="flat-warning"
            @click="() => {
              zatcaGroupName = 'all'
              refreshItems()
            }"
          >
            {{ $t("all") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: zatcaGroupName === 'posted' }]"
            variant="flat-warning"
            @click="() => {
              zatcaGroupName = 'posted'
              refreshItems()
            }"
          >
            {{ $t("posted") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: zatcaGroupName === 'unPosted' }]"
            variant="flat-warning"
            @click="() => {
              zatcaGroupName = 'unPosted'
              refreshItems()
            }"
          >
            {{ $t("unPosted") }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- buttons operations -->
    <b-row
      align-h="between"
      class="mb-1 mt-1"
    >
      <b-col
        md="12"
        class="list-buttons d-flex align-items-center justify-content-end mb-1 mb-md-0"
      >
        <vue-excel-xlsx
          :data="itemsArray"
          :columns="tableColumns"
          :filename="this.$route.name"
          :sheetname="'xlsxSheet'"
          class="btn btn-relief-success ml-1 p-0 ptn-sm"
        >
          <img
            src="@/assets/images/icons/xls.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </vue-excel-xlsx>
        <b-button
          variant="relief-danger"
          class="ml-1 p-0 ptn-sm"
          @click="printDocument"
        >
          <img
            src="@/assets/images/icons/pdf.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </b-button>
      </b-col>
    </b-row>

    <g-table
      ref="invoice-table"
      :items="itemsProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      foot-clone
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template #isReviewed="{ item }">
        <span>
          <b-form-group>
            <b-form-checkbox
              v-model="item.isReviewed"
              v-permission="'addSalesTransactions'"
              class="mr-0 mt-50"
              inline
              @change="(v) => {
                isReviewed(item);
              }
              "
            />
          </b-form-group>
        </span>
      </template>

      <template #isSentToZatca="{ item }">
        <span>
          <b-form-group>
            <b-form-checkbox
              v-model="item.isSentToZatca"
              class="mr-0 mt-50"
              inline
              disabled
            />
          </b-form-group>
        </span>
      </template>

      <template
        v-if="!hideActions"
        #actions="{ item }"
      >
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('preview')"
            data-action-type="preview"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-prev-icon`"
              icon="EyeIcon"
              class="mx-25 clickable"
              :hidden="true"
            />
          </b-button>
          <!-- create return Sales -->
          <b-button
            v-if="item.invoiceType === 'sales'"
            v-permission="'addReturnSalesTransactions'"
            v-b-tooltip.hover.top="$t('createReturnSales')"
            data-action-type="edit"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="() => {
              $router.push({
                name: 'return-invoice',
                query: {
                  sourceTransactionId: item.id,
                  sourceTransactionType: item.invoiceType,
                  hidePostLabel: true
                },
              });
            }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-fileText-icon`"
              icon="RefreshCwIcon"
              stroke="sienna"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editSalesTransactions'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="editInvoice(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="mx-25 clickable"
              stroke="lightslategray"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="(v) => {
              print(item);
            }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="olivedrab"
            />
          </b-button>
          <b-button
            v-show="item.invoiceType === 'sales'"
            v-b-tooltip.hover.top="$t('sendMail')"
            v-permission="'sendSalesTransactionsEmail'"
            data-action-type="send"
            variant="flat-warning"
            class="btn-icon"
            size="sm"
            @click="() => {
              sendMail(item);
            }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-send-icon`"
              icon="SendIcon"
            />
          </b-button>
          <b-button
            v-permission="'editSalesTransactions'"
            v-b-tooltip.hover.top="item.attachmentUrl !== null
              ? $t('hasAttachMents')
              : $t('hasNotAttachMents')
            "
            data-action-type="download"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="(v) => {
              if (item.attachmentUrl !== null) {
                downloadItem(item);
              }
            }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-attach-icon`"
              :icon="item.attachmentUrl !== null ? 'FolderPlusIcon' : 'FolderIcon'
              "
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deletediscount'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
          <b-button
           @click="sendWhatsAppMessage(item)"
            v-if="item.phone"
             data-action-type="contact"
            v-b-tooltip.hover.top="$t('whatsAppContact')"
            variant="whatsApp"
            class="btn-icon p-25"
            size="sm"
          >
              <img src="@/assets/images/icons/whatsapp.png" width="15" alt="" srcset="">
            </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      filterOn: [],
      filter: {
        transactionNumber: null,
        fromDate: null,
        toDate: null,
        studentName: null,
        stageId: null,
        rowId: null,
        classroomId: null,
        transactionType: null,
        isPosted: null
      },
      items: [],
      totalRows: 0,
      banks: [],
      totalVals: 0,
      modal: false,
      showFilter: false,
      postGroupName: 'all',
      transactionGroupName: 'all',
      zatcaGroupName: 'all',
      fiscalYearStart: null,
      fiscalYearEnd: null,
      encryptedId: '',
      itemsArray: [],
      hideActions: false,
      url: '',
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true
        },
        {
          key: 'invoiceType',
          field: 'invoiceType',
          label: this.$t('invoiceType'),
          sortable: true,
          formatter: (key, value, item) => {
            // const name = this.isRight ? item.transArabicName : item.transEnglishName;
            if (item.invoiceType === 'sales') {
              return `<span class="p-50 main-space badge bg-warning bg-darken-1"> ${this.$t(
                'salesPoints'
              )}</span>`;
            }
            if (item.invoiceType === 'returnSales') {
              return `<span class="p-50 main-space badge badge-light-success">${this.$t(
                'retuenSalesPoints'
              )}</span>`;
            }
          },
        },
        {
          key: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true
        },
        {
          key: 'studentName',
          label: this.$t('studentName'),
          sortable: true
        },
        {
          key: 'net',
          label: this.$t('total'),
          sortable: true,
          type: 'number',
          footer: () => this.fraction(this.totalVal),
        },
        {
          key: 'isReviewed',
          label: this.$t('reviewed')
        },
        {
          key: 'updatedStatus',
          field: 'updatedStatus',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if (tanx.invoiceType === 'sales' && tanx.isParent) return `<span class="p-50 main-space badge bg-success bg-darken-1">${this.$t('alreadyDone') + tanx.isParent}</span>`;
            if (tanx.codeList && tanx.codeList.code) return `<span class="p-50 main-space badge border-0  bg-info bg-darken-1">${this.$t('child') + tanx.codeList.code} </span>`;
            // if (tanx.isPosted) return `<span class="badge bg-secondary bg-darken-1">${this.$t('posted')}</span>`
            if (tanx.codeList && tanx.codeList.transactionType === 'salesOrder') return `<span class="p-50 main-space badge bg-info bg-darken-1">${this.$t('salesOrderChild') + tanx.codeList.code}</span>`;
            if (tanx.codeList && tanx.codeList.transactionType === 'quotation') return `<span class="p-50 main-space badge bg-warning bg-darken-1">${this.$t('quotationChild') + tanx.codeList.code}</span>`;
            if (tanx.codeList && tanx.codeList.code) return `<span class="p-50 main-space badge border-0  bg-info bg-darken-1">${this.$t('child') + tanx.codeList.code} </span>`;
            if(tanx.sourceFeesId) return `<span class="p-50 main-space badge bg-gradient-primary bg-darken-1">${this.$t('relatedToDeservedFeeNo', {feeNo: tanx.sourceFeesCode })}</span>`;
            if(tanx.referenceVoucherId) return `<span class="p-50 main-space badge bg-gradient-warning bg-darken-1">${this.$t('relatedToCollectionVoucherNo', {voucherNo: tanx.referenceVoucherCode })}</span>`;
            return `<span class="p-50 main-space badge border-0 bg-primary bg-darken-1">${this.$t('newTrans')}</span>`;
          },
        },
        {
          key: 'postStatus',
          label: this.$t('isPosted'),
          formatter: (key, value, tanx) => {
            if (tanx.isPosted) return `<span class="p-50 main-space badge bg-secondary bg-darken-1">${this.$t('posted')}</span>`
            return `<span class="p-50 main-space badge bg-danger bg-darken-2">${this.$t('unPosted')}</span>`;
          }
        },
        {
          key: 'isSentToZatca',
          label: this.$t('sentToZatca')
        },
        { key: 'actions' },
      ];
    },
  },
  watch: {
    'filter.fromDate'(newVal) {
      if (newVal > this.filter.toDate) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    },
    'filter.ToDate'(newVal) {
      if (this.filter.fromDate > newVal) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.filter.fromDate = this.getDate(this.fiscalYearStart)
    this.filter.toDate = this.getDate(this.fiscalYearEnd)
    // const prodction = document.location.host.indexOf('eduprosys.net');
    // if (prodction !== -1) {
    //   this.url = "https://eduproreporting.azurewebsites.net/"
    // } else {
    //   this.url= "http://108.181.191.44:3031/"
    // }
    this.loadData();
  },

  methods: {
    getMessage(item, reportUrl) {
      const to = `السيد ولي أمر الطالب / ${item.studentName}`
      const content = `إليك التقرير الخاص بفاتورة مبيعات رقم ${item.code} المقام بتاريخ ${item.transactionDate}`
      const greating = 'نتمني لك يوم سعيد'
      const instructions = 'رابط التحميل المباشر :'
      return `${to}%0A${content}%0A${greating}%0A%0A${instructions}%0A${reportUrl}`
    },

    sendWhatsAppMessage(item) {
      if (this.baseReportURL.slice(-1) === '?') {
        this.baseReportURL = this.baseReportURL.slice(0, -1);
      }

      this.get({ url: `invoices/encrypt/${item.id}` }).then((data) => {
        this.encryptedId = data;
        const reportUrl = encodeURIComponent(`${this.baseReportURL}/shareInvoice/${this.encryptedId}`)
        const message = this.getMessage(item, reportUrl);
        // window.open(`http://wa.me/${item.phone}?text=${this.baseReportURL}/shareInvoice/${this.encryptedId}` , '_blank');
        window.open(`http://web.whatsapp.com/send?phone='${item.phone}&icebreaker=${message}&&source_url=${reportUrl}` , '_blank');
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    isReviewed(item) {
      this.update({ url: `invoices/${item.id}/review-status/${item.isReviewed}` }).then(() => {
        this.$refs['invoice-table'].refreshTable();
      });
    },

    ResetSelection() {
      if (!this.showFilter) {
        this.filter.transactionNumber = null;
        this.filter.fromDate = this.fiscalYearStart;
        this.filter.toDate = this.fiscalYearEnd;
        this.filter.studentName = null;
        this.filter.stageId = null;
        this.filter.rowId = null;
        this.filter.classroomId = null;
        this.postGroupName = 'all';
        this.transactionGroupName = 'all';
        this.zatcaGroupName = 'all';
        this.refreshItems();
      }
    },

    loadData() {
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
      this.get({ url: 'Rows' }).then((data) => {
        this.rows = data;
      });
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classrooms = data;
      });
    },

    printDocument() {
        this.hideActions = true;
        setTimeout(() => {
          this.pdfExport(this.$route.name);
          this.hideActions = false;
        }, 500);
      },

      pdfExport(name) {
        html2canvas(document.getElementById("mainTable")).then(function (canvas) {
          var imgData = canvas.toDataURL("image/png");
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;

          var doc = new jsPDF("p", "mm");
          var position = 0;

          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save(`${name}.pdf`);
        });
      },

    setFilertForPost() {
      switch (this.postGroupName) {
        case 'posted':
          this.filter.isPosted = true;
          break;

        case 'unPosted':
          this.filter.isPosted = false;
          break;

        default:
          this.filter.isPosted = null;
          break;
      }
    },

    setFilertForSendingToZatca() {
      switch (this.zatcaGroupName) {
        case 'posted':
          this.filter.isSentToZatca = true;
          break;

        case 'unPosted':
          this.filter.isSentToZatca = false;
          break;

        default:
          this.filter.isSentToZatca = null;
          break;
      }
    },

    setFilertForTransaction() {
      switch (this.transactionGroupName) {
        case 'sales':
          this.filter.transactionType = 1;
          break;

        case 'returns':
          this.filter.transactionType = 2;
          break;

        default:
          this.filter.transactionType = null;
          break;
      }
    },

    itemsProvider(ctx, callback) {
      const
        {
          currentPage, perPage,
          sortBy, sortDesc
        } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      this.setFilertForPost();
      this.setFilertForTransaction();
      this.setFilertForSendingToZatca();

      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;

      this.get({ url: `Invoices${params}` })
        .then((data) => {
          this.isTableBusy = false;
          this.totalVal = 0
          this.totalRows = data.totalRecords;
          data.pageData.forEach((item) => {
            if (this.transactionGroupName === 'all') {
              switch (item.invoiceType) {
                case 'sales':
                  this.totalVal += item.net
                  break;
                case 'returnSales':
                  this.totalVal -= item.net
                  break;
                default:
                  this.totalVal = 0
                  break;
              }
            }
            else {
              this.totalVal += item.net;
            }
            item.invoiceDate = this.getDate(item.invoiceDate);
            item.transactionDate = this.getDate(item.transactionDate);
            // const transactionName = this.invoicesTypes.find((val) => val.id === item.transactionType) || {};
            const codeList = [];
            const isParent = [];
            const transList = data.pageData.filter((i) => i.id === item.sourceTransactionId);
            if (transList.length > 0) {
              transList.forEach((trans) => codeList.push(trans));
            }
            const parentList = data.pageData.filter((i) => i.sourceTransactionId === item.id);
            if (parentList.length > 0) {
              parentList.forEach((trans) => isParent.push(trans.code));
            }
            item.codeList = codeList[0] // transList.length > 0 ? codeList.toString() : '';
            item.isParent = parentList.length > 0 ? isParent.toString() : '';
            // status
            if (item.invoiceType === 'sales' && item.isParent) item.updatedStatus = `${this.$t('alreadyDone')} ${item.isParent}`;
            if (item.updatedStatus === '') item.updatedStatus = `${this.$t('newTrans')}`;
          });
          callback(data.pageData);

          // then set items for excel and pdf
          this.itemsArray = data.pageData;
          this.itemsArray.forEach(element => {
              element.postStatus = element.isPosted ? this.$t('posted') : this.$t('unPosted');
            });
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },

    refreshItems() {
      this.$refs['invoice-table'].refreshTable();
    },

    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'invoices', id: item.id }).then(() => {
            this.doneAlert({
              text: this.$t('deletedSuccessfully')

            });
            this.refreshItems();
          });
        }
      );
    },

    sendMail(invoice) {
      if (!invoice.guardianId) {
        this.doneAlert({ text: this.$t('studentNotRelatedToGuardain'), type: 'warning' });
        return
      }
      this.confirmAction({ text: this.$t('areYouSureYouWantToSendMail'), title: this.$t('sendMail'), confirmText: this.$t('yesSend') },
        () => {
          this.create({ url: `invoices/send-mail/${invoice.id}` }).then(() => {
            this.doneAlert({ text: this.$t('sendSuccessfully') });
          });
        }
      );
    },

    editInvoice(item) {
      var route = '';
      if (item.invoiceType === 'sales') {
        route = 'pos-invoice-edit';
      } else {
        route = 'return-invoice-edit';
      }
      this.$router.push({
        name: route,
        params: { id: item.id },
      });
    },

    print(item) {
      var reportName = '';
      if (item.invoiceType === 'sales') {
        reportName = this.isRight ? 'Invoice-ar' : 'Invoice-en';
      } else {
        reportName = this.isRight ? 'ReturnSales-ar' : 'ReturnSales-en';
      }

      const printedItem = {
        id: item.id,
        branchId: this.currentBranch.id,
        userName: this.currentUser
      }
      this.printReport(reportName, printedItem);
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    }
  },

};
</script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border: 2px solid #ad8884;
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: -3px;
}

.gb-package label {
  font-size: 14px;
  margin-bottom: 10px;
}

b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2)
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
